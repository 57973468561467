// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-certs-js": () => import("./../../../src/pages/certs.js" /* webpackChunkName: "component---src-pages-certs-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-projects-asociacion-comerciantes-js": () => import("./../../../src/pages/projects/asociacion-comerciantes.js" /* webpackChunkName: "component---src-pages-projects-asociacion-comerciantes-js" */),
  "component---src-pages-projects-beautiful-words-js": () => import("./../../../src/pages/projects/beautiful-words.js" /* webpackChunkName: "component---src-pages-projects-beautiful-words-js" */),
  "component---src-pages-projects-bloglist-app-js": () => import("./../../../src/pages/projects/bloglist-app.js" /* webpackChunkName: "component---src-pages-projects-bloglist-app-js" */),
  "component---src-pages-projects-bombolo-app-js": () => import("./../../../src/pages/projects/bombolo-app.js" /* webpackChunkName: "component---src-pages-projects-bombolo-app-js" */),
  "component---src-pages-projects-copy-on-select-js": () => import("./../../../src/pages/projects/copy-on-select.js" /* webpackChunkName: "component---src-pages-projects-copy-on-select-js" */),
  "component---src-pages-projects-doremify-js": () => import("./../../../src/pages/projects/doremify.js" /* webpackChunkName: "component---src-pages-projects-doremify-js" */),
  "component---src-pages-projects-pop-them-all-js": () => import("./../../../src/pages/projects/pop-them-all.js" /* webpackChunkName: "component---src-pages-projects-pop-them-all-js" */),
  "component---src-pages-projects-portfolio-js": () => import("./../../../src/pages/projects/portfolio.js" /* webpackChunkName: "component---src-pages-projects-portfolio-js" */),
  "component---src-pages-projects-random-bookmark-machine-js": () => import("./../../../src/pages/projects/random-bookmark-machine.js" /* webpackChunkName: "component---src-pages-projects-random-bookmark-machine-js" */),
  "component---src-pages-projects-simple-counter-button-js": () => import("./../../../src/pages/projects/simple-counter-button.js" /* webpackChunkName: "component---src-pages-projects-simple-counter-button-js" */),
  "component---src-pages-projects-stack-explorer-js": () => import("./../../../src/pages/projects/stack-explorer.js" /* webpackChunkName: "component---src-pages-projects-stack-explorer-js" */),
  "component---src-pages-projects-vba-code-obfuscator-js": () => import("./../../../src/pages/projects/vba-code-obfuscator.js" /* webpackChunkName: "component---src-pages-projects-vba-code-obfuscator-js" */),
  "component---src-pages-projects-video-qa-app-js": () => import("./../../../src/pages/projects/video-qa-app.js" /* webpackChunkName: "component---src-pages-projects-video-qa-app-js" */),
  "component---src-pages-projects-vinylizr-js": () => import("./../../../src/pages/projects/vinylizr.js" /* webpackChunkName: "component---src-pages-projects-vinylizr-js" */),
  "component---src-pages-projects-zenter-js": () => import("./../../../src/pages/projects/zenter.js" /* webpackChunkName: "component---src-pages-projects-zenter-js" */),
  "component---src-pages-skills-js": () => import("./../../../src/pages/skills.js" /* webpackChunkName: "component---src-pages-skills-js" */)
}

