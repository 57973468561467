module.exports = [{
      plugin: require('../node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[],"maxWidth":960,"backgroundColor":"transparent","linkImagesToOriginal":false,"wrapperStyle":"pointer-events: none","showCaptions":false,"markdownCaptions":false,"sizeByPixelDensity":false,"quality":50,"withWebp":false,"tracedSVG":false,"loading":"lazy","disableBgImageOnAlpha":false,"disableBgImage":false},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"micpob","short_name":"micpob","start_url":"/","background_color":"black","theme_color":"black","display":"minimal-ui","icon":"src/images/icon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"4aaad322aca045ba1d9fa49e6339f393"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
